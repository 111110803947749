<template>
  <div class="flex flex-col bg-[#F7F7F7]">
    <StoryblokComponent v-for="component in blok.bloks" :key="component._uid" :blok="component" />
    <Footer />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { VonageHomePage } from '@/types/storyblok';
import Footer from '@/components/footer/Footer.vue';
import { useVividSSR } from '@/utils/vivid-ssr';
import { setSEOTags } from '@/utils/blog/seo';

useVividSSR();

defineProps({
  blok: { type: Object as PropType<VonageHomePage>, required: true },
});

await setSEOTags({
  robotsTag: 'all',
});
</script>
